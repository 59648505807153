<template>
  <div style="width:100%">
      
    <AllOrderList v-if="editType == 'list'" @changeShowType="changeShowType"></AllOrderList>

    <StuOrderInfo v-if="editType != 'list' && orderType =='stu'" :editId="editId"  @changeShowType="changeShowType"></StuOrderInfo>
    <TeacherOrderInfo v-if="editType != 'list' && orderType =='teacher'" :editId="editId"  @changeShowType="changeShowType"></TeacherOrderInfo>
  </div>
</template>

<script>
import AllOrderList from './components/AllOrderList'
import StuOrderInfo from '../components/StuOrderInfo'
import TeacherOrderInfo from '../components/TeacherOrderInfo'
import {  } from '@/api/index.js'
export default {
  name: 'allOrder',
  components: {  AllOrderList,StuOrderInfo,TeacherOrderInfo},
  data() {
    return {
      editType:'list',
      orderType:'',
      editId:'',
    }
    
  },
  created() {
  },
  methods: {
    changeShowType(type,id,orderType){
      this.editId = id;
      this.editType = type;
      this.orderType = orderType;
    }
  }
}
</script>

<style>
</style>