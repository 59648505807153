<template>
  <div style="width:100%">
    <div class="search-box">
      <el-form inline size="small">
        <el-form-item label="订单类型">
          <el-select v-model="form.orderType" placeholder="请选择" style="width:100px">
            <el-option label="全部" value=""></el-option>
            <el-option label="学生订单" value="1"></el-option>
            <el-option label="教师订单" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年份：">
          <el-date-picker v-model="form.year" format="yyyy" value-format="yyyy" type="year" placeholder="请选择年份" style="width:100px"> </el-date-picker>
        </el-form-item>
        <el-form-item label="学期">
          <el-select v-model="form.semester" placeholder="请选择" style="width:100px">
              <el-option label="全部" value=""></el-option>
              <el-option label="春季学期" value="0"></el-option>
              <el-option label="秋季学期" value="1"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="征订批次"  >
            <el-select v-model="form.batch" placeholder="请选择" style="width:100px">
              <el-option  value="" label="全部" ></el-option>      
              <el-option  v-for="i in 10" :key="i" :value="i" :label="i"></el-option>   
            </el-select>
          </el-form-item>  
          <el-form-item label="地区" >
            <ChooseArea ref="chooseArea" v-model="areaValue"  @changeArea="changeArea" :checkStrictly="true" :selectedLevel="4" style="width:250px" ></ChooseArea>
          </el-form-item>
          <el-form-item label="学校" >
            <el-select v-model="schoolId" placeholder="请选择" style="width:150px" @change="schoolChange" :disabled="(form.proId || form.cityId || form.regId || form.streetId)?false:true">
              <el-option label="全部" value=""></el-option>
              <el-option v-for="schoolInfo in schoolList" :key="schoolInfo.id" :label="schoolInfo.name" :value="schoolInfo.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年级">
            <el-select v-model="gradeId" placeholder="请选择" style="width:100px" @change="gradeChange" :disabled="!schoolId">
              <el-option label="全部" value=""></el-option>
              <el-option v-for="gradeInfo in gradeList" :key="gradeInfo.id" :label="gradeInfo.name" :value="gradeInfo.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="班级">
          <el-select v-model="classId" placeholder="请选择" @change="classChange" style="width:100px" :disabled="!gradeId">
              <el-option label="全部" value=""></el-option>
              <el-option v-for="classInfo in classList" :key="classInfo.id" :label="classInfo.name" :value="classInfo.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="支付时间">
          <el-date-picker v-model="paymentTimeRang"  type="daterange" align="right" style="width:220px;"
             unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="状态">
          <ChooseOrderState v-model="form.status" :showAll="true"></ChooseOrderState>
        </el-form-item>
        <el-form-item label="模糊搜索">
          <el-input v-model="form.search" placeholder="学校、名字、手机号码"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list" style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);" size="small" :stripe="true" :border="true">
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
       <el-table-column label="订单类型" align="center" show-overflow-tooltip width="80">
        <template slot-scope="scope">
          <span >{{scope.row.orderType=='1'?'学生征订':'教师征订'}}</span>
        </template>
      </el-table-column>
       <el-table-column label="状态" align="center" show-overflow-tooltip width="80">
        <template slot-scope="scope">
          <span >{{getOrderStatusStr(scope.row.status)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="年份/学期" align="center" show-overflow-tooltip width="80">
        <template slot-scope="scope">
          <span >{{scope.row.year}}年<br />
          {{scope.row.semester==0?'春季学期':'秋季学期'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="地区" align="left" show-overflow-tooltip width="140">
        <template slot-scope="scope">
          <span >{{scope.row.proName+","+scope.row.cityName}}</span><br />
          <span >{{scope.row.regName+","+scope.row.streetName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="学校" align="left" show-overflow-tooltip width="140">
        <template slot-scope="scope">
          <span >{{scope.row.schoolName}}</span><br />
          <span v-if="scope.row.orderType==1" >{{scope.row.gradeName+','+scope.row.className}}</span>
        </template>
      </el-table-column>
     
      <el-table-column label="姓名" align="center" show-overflow-tooltip width="100" >
        <template slot-scope="scope">
          <span >{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="手机号码" align="center" show-overflow-tooltip width="100">
        <template slot-scope="scope">
          <span >{{scope.row.phone}}</span>
         </template>
      </el-table-column>    
      <el-table-column label="订单号" align="left"  width="250">
        <template slot-scope="scope">
          <span >{{'订单号：'+scope.row.code}}</span><br />
          <span >{{'微：'+(scope.row.paymentStream || '-')}}</span>
        </template>
      </el-table-column> 
      <el-table-column label="支付信息" align="left"  width="180">
        <template slot-scope="scope">
          <span >{{'时间：'+(scope.row.paymentTime || '-')}}</span><br />
          <span >{{'金额：￥'+(scope.row.paymentMoney || '-')}}</span>
        </template>
      </el-table-column> 
      <el-table-column label="退款信息" align="left" show-overflow-tooltip width="180">
        <template slot-scope="scope">
          <span >{{'时间：'+ (scope.row.refundTime || '-') }}</span><br />
          <span >{{'金额：'+(scope.row.refundMoney || '-') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="套餐信息" prop="createTime" align="center">
        <template slot-scope="scope">
        <span >{{scope.row.packageName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="批次" prop="createTime" align="center" width="60">
        <template slot-scope="scope">
         <span >{{scope.row.batch}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="150">
        <template slot-scope="scope">
          <el-button type="text"  @click="editDetail(scope.row)">查看</el-button>
          <el-button type="text"  @click="editDetail(scope.row.id,'edit')">编辑</el-button>
          <el-button type="text"  style="color:red" @click="returnMoney(scope.row)" v-if="scope.row.status=='2' || scope.row.status=='4'">退款</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>
    <ReturnForm v-if="isShowReturnModal" v-model="isShowReturnModal" :returnOrderInfo="returnOrderInfo"  @success="getList"></ReturnForm>
  </div>
</template>

<script>
import {  getAllOrderList,getAllSchoolList,getGradeBySchoolId,getClassByGradeId } from '@/api/index.js'
import ChooseArea from '@/components/ChooseArea/index.vue'
import ChooseOrderState from '@/components/ChooseOrderState/index.vue'
import ReturnForm from '../../components/ReturnForm'
export default {
  name: 'capital',
  components: { ChooseArea,ChooseOrderState,ReturnForm },
  data() {
    return {
      showType: 0,
      editType: '',
      isAddDialog:false,
      isFastAddDialog:false,
      form: {
        year:'',
        semester:'',
        batch:'',
        proId:'',
        cityId:'',
        regId:'',
        streetId:'',
        status:'',        
        schoolName:'',     
        gradeName:'',
        className:'',  
        pageNum: 1,          //页码
        pageSize: 10,          //每页条数
        search: '',          //搜索条件
        startTime:'',
        endTime:'',
        orderType:''
      },
      list: [],
      total: 0,
      editType: '',
      areaValue:['','','',''],
      paymentTimeRang:[],
      schoolList:[],
      gradeList:[],
      classList:[],
      schoolId:'',
      gradeId:'',
      classId:'',
      isShowReturnModal:false,
      returnOrderInfo:{}
    }
    
  },
  created() {
    let nowDate = new Date();
    this.form.year = nowDate.getFullYear()+'';
    this.getList();
  },
  methods: {
    getOrderStatusStr(statusCode){
      let statusStr = '';
      switch(statusCode){
        case '0':
          statusStr = '待支付';
          break;
        case '1':
          statusStr = '支付中';
          break;
        case '2':
          statusStr = '已支付';
          break;
        case '3':
          statusStr = '全额退款';
          break;
        case '4':
          statusStr = '部分退款';
          break;
        case '5':
          statusStr = '已关闭';
        case '6':
          statusStr = '退款中';
          break;
      }
      return statusStr;
    },
    returnMoney(orderInfo){
      this.returnOrderInfo = {
        code:orderInfo.code,
        refundableAmount:orderInfo.refundableAmount,
        name:orderInfo.name
      }
      this.isShowReturnModal = true;
    },
    changeArea(selectedData){
      this.form.proId = selectedData.proId;
      this.form.cityId=selectedData.cityId;
      this.form.regId=selectedData.regId;
      this.form.streetId=selectedData.streetId;
      if(this.form.proId || this.form.cityId || this.form.regId || this.form.streetId){
        this.getSchoolList();
      }else{
        this.schoolList=[];
        this.schoolId = ''
        this.form.schoolName = '';
        this.form.gradeName = '';
        this.form.className = '';
      }
    },
    goSetGrade(schoolTypeInfo){
      this.$emit('changeShowType', 'grade',schoolTypeInfo)
    },
    editDetail(orderInfo) {
      let orderTypeStr = orderInfo.orderType==1?'stu':'teacher'
      this.$emit('changeShowType', 'info',orderInfo.id,orderTypeStr)
    },
    getList() {
      if(this.paymentTimeRang.length>0){
        this.form.startTime = this.paymentTimeRang[0];
      }
      if(this.paymentTimeRang.length>1){
        this.form.endTime = this.paymentTimeRang[1];
      }
      getAllOrderList(this.form).then(res => {
        if (res.code != 200) return
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.getList()
    },
    getSchoolList(){
      let param = {
          isPage:0,
          proId: this.form.proId,
          cityId: this.form.cityId,
          regId: this.form.regId,
          streetId: this.form.streetId
        };
      getAllSchoolList(param).then(res => {
        if (res.code != 200) return
        this.schoolList = res.data
      })   

    },
    schoolChange(schoolId){
      this.form.schoolName = '';
      if(!schoolId){
        
        this.gradeList=[];
        this.form.gradeName='';
        this.classList = [];
        this.form.className='';
        return;
      }
      let obj = this.schoolList.find((item)=>{
        return item.id == schoolId;
      })
      this.form.schoolName = obj.name;
      let param={
        schoolId:schoolId
      };
      getGradeBySchoolId(param).then(res => {
        if (res.code != 200) return
        this.gradeList = res.data
      })   
    },
    gradeChange(gradeId){
      this.form.gradeName = '';
      if(!gradeId){
        this.classList = [];
        this.form.className='';
        return;
      }
      let obj = this.gradeList.find((item)=>{
        return item.id == gradeId;
      })
      this.form.gradeName = obj.name;
      let param={
        gradeId:gradeId
      };
      getClassByGradeId(param).then(res => {
        if (res.code != 200) return
        this.classList = res.data
      })   
    },
    classChange(classId){
      this.form.className = '';
      let obj = this.classList.find((item)=>{
        return item.id == classId;
      })
      this.form.className = obj.name;
    },
  }
}
</script>

<style>
</style>